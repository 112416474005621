<template>
  <div class="sales-addons-list fixed-draggable-dynamic-table-wrapper-height">
    <draggable-dynamic-table ref="saleClosedTrash"
                             :options="options"
                             :columns="columnsLabel"
                             :data="data"
                             :zero-top-radius="true"
                             @filter:remove="setFilter($event, true)"
                             @filter:set="setFilter($event)"
                             @sort:set="setSort($event)"
                             @user:restore="restoreClosedInvoice($event)"
                             @user:deleted="$refs.deleteConfirmation.showDialog(), selectedRow = $event"
                             @load:more="getClosedInvoiceTrash"/>

    <custom-dialog ref="deleteConfirmation"
                   :title="$t('closed.confirmations.forceDelete.title')"
                   :body="$t('closed.confirmations.forceDelete.body', {name: selectedRow.description})"
                   @accept="deleteClosedInvoice(selectedRow)"
                   @close=""/>
  </div>
</template>

<script>
  import axios from 'axios'
  import CustomIcon from "../../../../../components/customIcon/customIcon.vue";
  import CustomDialog from "../../../../../components/customDialog/customDialog.vue";
  import {checkUserPermissions} from "../../../../../assets/js/functions";
  import {
    deleteClosedInvoice,
    getClosedInvoiceTrash,
    restoreClosedInvoice
  } from "../../../../../http/requests/club/closedInvoices";

  export default {
    name: "saleClosedTrash",
    components: {CustomDialog, CustomIcon},
    data() {
      return {
        selectedRow: {},
        filters: {},
        sorts: ['order[0]=created_at,desc'],
        data: [],
        page: 1,
        total_count: null,
        loadingTimer: 0,
        options: {
          id: 'saleClosedTrash',
          rowKeyField: 'id'
        },
        columnsLabel: [
          // {
          //   field: 'storeAction',
          //   i18n: 'closed.list.table.header.restore',
          //   color: 'success',
          //   width: '70px',
          //   minWidth: 70,
          //   locked: true,
          //   actions: true,
          //   showAction: 'always',
          //   event: 'user:restore',
          //   action: {
          //     icon: 'REFRESH',
          //     iconPack: 'useral',
          //     color: 'success',
          //     type: 'button'
          //   },
          //   classes: 'mx-auto'
          // },
          {
            field: 'deleteAction',
            i18n: 'closed.list.table.header.delete',
            color: 'danger',
            width: '70px',
            minWidth: 70,
            align: 'center',
            locked: true,
            actions: true,
            showAction: 'always',
            event: 'user:deleted',
            action: {
              icon: 'TRASH',
              iconPack: 'useral',
              color: 'danger',
              type: 'button'
            },
            classes: 'mx-auto'
          },
          {
            field: 'timesCount',
            i18n: 'closed.list.table.header.timesCount',
            width: 'calc(100% / 10)',
            minWidth: 150,
            sortable: true,
            filter: true,
            filterTypes: [
            {
              icon: 'equals',
              name: 'برابر باشد',
              i18n: 'draggableTable.filter.types.equals',
              id: 1
            },
            {
              icon: 'not-equal',
              name: 'برابر نباشد',
              i18n: 'draggableTable.filter.types.notEqual',
              id: 2
            },
            {
              icon: 'less-than-equal',
              name: 'کوچکتر مساوی',
              i18n: 'draggableTable.filter.types.lessThanEquals',
              id: 4
            },
            {
              icon: 'greater-than-equal',
              name: 'بزرگتر مساوی',
              i18n: 'draggableTable.filter.types.greaterThanEquals',
              id: 3
            }
            ],
          },
          {
            field: 'creator',
            i18n: 'closed.list.table.header.creator',
            align: 'center',
            width: 'calc((100% / 10) * 2)',
            minWidth: 200,
            sortable: true,
            filter: true,
            filterTypes: [
              {
                icon: 'search',
                name: 'شامل شود',
                i18n: 'draggableTable.filter.types.search',
                id: 1
              }
            ]
          },
          {
            field: 'createdAt',
            i18n: 'closed.list.table.header.createdAt',
            align: 'center',
            width: 'calc((100% / 10) * 2)',
            minWidth: 200,
            sortable: true,
            filter: true,
            filterType: 'date',
          },
          {
            field: 'description',
            i18n: 'closed.list.table.header.description',
            align: 'center',
            width: 'calc((100% / 10) * 2)',
            minWidth: 200,
            sortable: true,
            filter: true,
            filterTypes: [
              {
                icon: 'search',
                name: 'شامل شود',
                i18n: 'draggableTable.filter.types.search',
                id: 1
              }
            ]
          },
        ],
        actions: [
          {
            leftToolbar: [
              {
                id: 'printTable',
                // i18n: 'draggableDynamicTable.actions.print',
                icon: 'PRINT',
                iconPack: 'useral'
              },
              {
                id: 'downloadTable',
                // i18n: 'draggableDynamicTable.actions.download',
                icon: 'DOWNLOAD',
                iconPack: 'useral'
              },
              {
                id: {name: 'closedInvoices'},
                type: 'link',
                icon: 'CHEVRON_LEFT',
                iconPack: 'useral'
              }
            ]
          }
        ]
      }
    },
    created() {
      if (!checkUserPermissions('closed_invoice.forceDelete')) {
        for (let i = 0; i < this.columnsLabel.length; i++) {
          if (this.columnsLabel[i].field === 'deleteAction') {
            this.columnsLabel.splice(i, 1)
          }
        }
      }

      if (!checkUserPermissions('closed_invoice.restore')) {
        for (let i = 0; i < this.columnsLabel.length; i++) {
          if (this.columnsLabel[i].field === 'storeAction') {
            this.columnsLabel.splice(i, 1)
          }
        }
      }

      this.$nextTick(() => {
        this.$store.dispatch('auth/setAccessToken')
        this.$store.dispatch('updateNavbarActions', this.actions[0])
        this.$store.dispatch('updateContentNavbarStyle', 'sticky')
      })

      this.getClosedInvoiceTrash()
    },
    methods: {
      checkUserPermissions(permission) {
        return checkUserPermissions(permission)
      },
      getClosedInvoiceTrash() {
        clearTimeout(this.loadingTimer)
        this.loadingTimer = setTimeout(() => {
          if (this.data.length < this.total_count || !this.total_count) {
            if (this.$refs.saleClosedTrash && this.data.length === 0) this.$refs.saleClosedTrash.loadMoreStatus = 'FirstLoad'
            else if (this.$refs.saleClosedTrash && this.data.length > 0) this.$refs.saleClosedTrash.loadMoreStatus = 'Loading'

            getClosedInvoiceTrash(this.page, this.filters, this.sorts).then((response) => {
              const invoices = response.data

              if (invoices.data.length > 0) {
                invoices.data.forEach((invoice) => {

                  this.data.push({
                    route: {name: 'closedInvoiceProfile', params: {id: invoice.id}},
                    id: invoice.id,
                    description: invoice.note || '',
                    timesCount: invoice.lines_count || '',
                    creator: invoice.creator.role && invoice.creator.role.id === 1 ? this.$t('closed.labels.bySystem') : `${invoice.creator.name || ''} ${invoice.creator.family || ''}`,
                    createdAt: invoice.invoice_date.split(' ')[0]
                  })
                })
              }


              this.total_count = invoices.pagination.total
              this.page = invoices.pagination.current_page + 1

              // if (response.data.pagination.current_page === 1) {
              //   const row_index = this.columnsLabel.map((e) => {
              //     return e.field
              //   }).indexOf('rowNumber')
              //   this.columnsLabel[row_index].footer.value = response.data.pagination.total
              // }

              if (this.$refs.saleClosedTrash) this.$refs.saleClosedTrash.loadMoreStatus = ''
            }).catch((error) => {
              if (this.$refs.saleClosedTrash && !axios.isCancel(error)) this.$refs.saleClosedTrash.loadMoreStatus = 'Danger'
            })
          }
        }, 400)
      },
      setFilter(filters) {
        let filters_list = {}
        Object.keys(filters).forEach((key) => {
          switch (key) {
            case 'description':
              if (filters[key].search !== '') filters_list.note = filters[key].search
              break

            case 'createdAt':
              if (filters[key].search !== '') filters_list.date = filters[key].search
              break

            case 'creator':
              let search = filters[key].search
              if (search === 'سیستم' || search === 'سیستمی') {
                search = 'system'
              }
              if (filters[key].search !== '') filters_list.creator = search
              break

            case 'timesCount':
              if (filters[key].search !== '') filters_list.linesCount = `${filters[key].search},${filters[key].type.id}`
              break
          }
        })

        this.data = []
        this.page = 1
        this.total_count = 0
        this.filters = filters_list
        this.getClosedInvoiceTrash()
      },
      setSort(sorts) {
        const sorts_list = []
        Object.keys(sorts).forEach((key) => {
          switch (key) {
            case 'description':
              sorts_list.push(`order[0]=note,${sorts[key] ? 'desc' : 'asc'}`)
              break

            case 'createdAt':
              sorts_list.push(`order[0]=invoice_date,${sorts[key] ? 'desc' : 'asc'}`)
              break

            case 'creator':
              sorts_list.push(`order[0]=creator,${sorts[key] ? 'desc' : 'asc'}`)
              break

            case 'timesCount':
              sorts_list.push(`order[0]=lines_count,${sorts[key] ? 'desc' : 'asc'}`)
              break
          }
        })

        if (sorts_list.length === 0) {
          this.sorts.push('order[0]=updated_at,desc')
        }

        this.data = []
        this.page = 1
        this.total_count = 0
        this.sorts = sorts_list
        this.getClosedInvoiceTrash()
      },
      restoreClosedInvoice (row) {
        restoreClosedInvoice(row.id).then(res => {
          this.$vs.notify({
            title: this.$t('alert.message.title'),
            text: this.$t('closed.notifications.restore.success'),
            color: 'success',
            time: 2400,
            icon: 'icon-check-circle',
            iconPack: 'feather'
          })

          this.data = []
          this.getClosedInvoiceTrash()
        })
          .catch((error) => {
            if (axios.isCancel(error)) {
              this.$vs.notify({
                title: this.$t('alert.duplicateRequest.title'),
                text: this.$t('alert.duplicateRequest.message'),
                icon: 'icon-alert-circle',
                iconPack: 'feather',
                time: 2400,
                color: 'warning'
              })
            } else {
              this.$vs.notify({
                title: this.$t('alert.error.title'),
                text: this.$t('closed.notifications.restore.error'),
                color: 'danger',
                icon: 'icon-alert-circle',
                iconPack: 'feather',
                time: 2400
              })
            }
          })
      },
      deleteClosedInvoice (invoice) {
        deleteClosedInvoice(invoice.id, true).then(res => {
          this.$vs.notify({
            title: this.$t('alert.message.title'),
            text: this.$t('closed.notifications.delete.success'),
            color: 'success',
            time: 2400,
            icon: 'icon-check-circle',
            iconPack: 'feather'
          })

          this.data = []
          this.page = 1
          this.total_count = 0
          this.getClosedInvoiceTrash()
        })
          .catch((error) => {
            if (axios.isCancel(error)) {
              this.$vs.notify({
                title: this.$t('alert.duplicateRequest.title'),
                text: this.$t('alert.duplicateRequest.message'),
                icon: 'icon-alert-circle',
                iconPack: 'feather',
                time: 2400,
                color: 'warning'
              })
            } else {
              this.$vs.notify({
                title: this.$t('alert.error.title'),
                text: this.$t('closed.notifications.delete.error'),
                color: 'danger',
                icon: 'icon-alert-circle',
                iconPack: 'feather',
                time: 2400
              })
            }
          })
      },
      handleReloadList() {
        this.data = []
        this.page = 1
        this.total_count = 0
        this.getClosedInvoiceTrash()
      },
      handleClick(id) {
        document.getElementById(id).click()
      }
    }
  }
</script>

<style scoped>

</style>
